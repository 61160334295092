.survey-activity-summary-container{
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.survey-activity-dashboard-fieldset{
    align-self: center;
    width: 100%;
    height: 100%;
    padding: 20px 20px 106.5px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border: 1px solid #bbb;
}

.survey-activity-summary-inner-container{
    position: relative;
    width: 80%;
    height: 50%;
    margin: 2rem;
}

.survey-activity-dashboard-total-responses{
    margin: 10px 0;
}

.survey-activity-dashboard-average-score{
    margin-bottom: 20px;
}

.survey-activity-dashboard-chart-container{
    width: 60%;
    align-self: center;
}

.survey-activity-chart-inner-container-utf{
    align-self: center;
    margin-left: -7rem;
    width: 50%;
}

.survey-activity-chart-inner-container-horizontal-bars{
    align-self: center;
    width: 90%;
}

.survey-activity-chart-detail-button{
    position: absolute;
    bottom: 30px;
    right: 21px;
}

.cast-toolbar__action {
    text-decoration: none;
}

.survey-activity-chart-detail-button-extra-margin{
    margin-top: 40px;
}

.survey-activity-dashboard-details-header th{
    font-weight: bold !important;
}