.cast-form-profile {
  padding: 24px;

  .cast-form-profile__title {
    position: relative;
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 30px;
    font-weight: 500;
  }

  .cast-form-profile__section {
    padding: 0 8px;

    &:not(:last-child) {
      padding-bottom: 24px;
    }
  }

  .cast-form-profile__field {
    position: relative;
    width: 20%;
    min-width: 240px;
    margin-bottom: 12px;

    .MuiTextField-root {
      width: 100%;
    }
  }
  
  .PrivateNotchedOutline-legendLabelled-117 > span {
    padding-right: 5px;
    padding-left: 0;
    font-size: 11px;
  }
}