.cast-form {
  padding: 24px;

  .cast-form__title {
    position: relative;
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 30px;
    font-weight: 500;
  }

  .cast-form__labels {
    float: right;
  }

  .cast-form__subtitle {
    position: absolute;
    bottom: -16px;
    left: 0;
    cursor: copy;
    font-size: 14px;
    line-height: 12px;
    margin-left: 4px;
    font-weight: 400;
    color: grey;
  }

  .cast-form__subtitle-student-detail {
    margin-top: 10px;
  }
  .cast-form__subtitle-student-detail span {
    font-size: 16px;
    margin-right: 20px;
  }

  .cast-form__section {
    padding: 0 8px;

    &:not(:last-child) {
      padding-bottom: 24px;
    }
  }

  .cast-form__section-report {
    display: flex;

    .cast-form__title {
      .cast-form__subtitle {
        position: relative;
        bottom: 0px;
        line-height: normal;
        font-size: 15px;
      }
    }

    .cast-toolbar__action {
      float: right;
      margin-left: auto;
      color: initial;
      button {
        display: inline-block;
      }
    }
  }

  .create-activity-menu-item{
    text-decoration: none !important;
    color: initial;
  }

  .create-button-list-item{
    color: #284358;
  }

  .cast-form__action-group {
    display: flex;
    padding: 8px;
  }

  .cast-form__action {
    padding: 8px 18px;

    &:not(:last-child) {
      margin-right: 12px;
    }
  }

  .likert-scale-button-select{
    margin-bottom: 20px;
    padding: 10px 50px;
  }

  .likert-scale-error-text{
    color: red;
    font-size: 14px;
  }

  .likert-scale-error-text span{
    margin-left: 5px;
  }

  .likert_scale_selected_answers{
    padding-top: 20px;
  }
  
  .cast-form__radio-group {
    display: flex;
    flex-direction: row;
    align-items: center;

    > :first-child {
      margin-right: 8px;
      font-weight: 600;
    }
  }

  .cast-form__checkbox {
    width: 56px;
    height: 54px;
    margin-bottom: 12px;
  }

  .cast-form__box {
    display: flex;
    align-items: flex-start;
  }

  .cast-form__box-add-answer {
    display: flex;
    align-items: flex-start;
    margin: 8px 0px 8px 100px;
  }

  .cast-form__box-answer {
    position: relative;
    &:hover {
      .cast-form__icon_answer {
        opacity: 1;
        transform: none;
      }
    }
  }

  .cast-form__field {
    position: relative;
    width: 50%;
    min-width: 240px;
    margin-bottom: 12px;

    &:hover {
      .cast-form__icon {
        opacity: 1;
        transform: none;
      }
    }

    .MuiTextField-root {
      width: 100%;
    }
  }

  .cast-form__feedback {
    margin-left: 8px;
    cursor: pointer;
    min-width: 180px;

    svg {
      fill: #284358;
    }
  }

  .cast-form__feedback-box {
    position: relative;
    display: flex;
    flex-direction: column;
  }

  .cast-form__feedback-message {
    margin-bottom: 12px;
  }

  .cast-form__correct-answer-message {
    margin: 25px 0px 0px 50px;
    color: red;
    font-size: 14px;
  }

  .cast-form__correct-answer-message-tip {
    margin: 25px 0px 0px 50px;
    font-size: 14px;
  }

  .cast-form__icon {
    position: absolute;
    top: -10px;
    right: -10px;
    background: #fafafa;
    fill: red;
    opacity: 0;
    transform: scale(0.8);
    transition: all 0.2s cubic-bezier(0.27, 0.09, 0.83, 1);
    cursor: pointer;
  }

  .cast-form__icon_answer {
    position: absolute;
    top: -10px;
    right: -10px;
    background: #fafafa;
    fill: red;
    opacity: 0;
    transform: scale(0.8);
    transition: all 0.2s cubic-bezier(0.27, 0.09, 0.83, 1);
    cursor: pointer;
  }

  .cast-form__ungraded_answer {
    width: 35px;
    height: 35px;
    margin-top: 10px;
    margin-right: 10px;
    color: gray;
  }

  .cast-form__correct_answer {
    width: 35px;
    height: 35px;
    margin-top: 10px;
    margin-right: 10px;
    color: green;
  }

  .cast-form__incorrect_answer {
    width: 35px;
    height: 35px;
    margin-top: 10px;
    margin-right: 10px;
    color: red;
  }

  .cast-form__hidden_answer {
    width: 35px;
    height: 35px;
    margin-top: 10px;
    margin-right: 10px;
    color: transparent;
  }

  .cast-form__answer_total {
    text-align: center;
    vertical-align: middle;
    line-height: 35px;
    width: 35px;
    margin: 10px 10px 0px 10px;
  }

  .PrivateNotchedOutline-legendLabelled-117 > span {
    padding-right: 5px;
    padding-left: 0;
    font-size: 11px;
  }
}

.cast-form-box {
  position: relative;
  display: flex;
  padding: 24px 24px 12px;
  margin-bottom: 24px;
  min-width: 240px;
  user-select: none;
  background: white;
  transition: opacity 0.4s ease-in-out;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, .2), 0 2px 2px 0 rgba(0, 0, 0, .14), 0 1px 5px 0 rgba(0, 0, 0, .12);

  &:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
  }
}

.cast-form-box-ff {
  position: relative;
  display: flex;
  padding: 24px 24px 12px;
  margin-bottom: 24px;
  min-width: 240px;
  user-select: none;
  background: white;
  transition: opacity 0.4s ease-in-out;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, .2), 0 2px 2px 0 rgba(0, 0, 0, .14), 0 1px 5px 0 rgba(0, 0, 0, .12);

  .cast-form__ff-grading-box {
    display: flex;
    justify-content: center;
  }

  .cast-form__ff-grading {
    margin: 12px;
    align-items: center;

    legend {
      color: red;
    }
  }

}

.cast-form__correct-box {
  border: lightgreen 2px solid;
  border-radius: 8px;
}

.cast-form__incorrect-box {
  border: #ff6b6b 2px solid;
  border-radius: 8px;
}

.cast-form__average-box {
  border: #FFDD00 2px solid;
  border-radius: 8px;
}

.cast-form__student-detail-incorrect {
  background-color: #ff6b6b;
}

.cast-form__student-detail-correct {
  background-color: lightgreen;
}

.cast-form__student-detail-ungraded {
  background-color: lightgrey;
}

.cast-form__performance-title {
  font-size: 14px;
  color: #284358;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  margin-left: 49.5%;
}

.cast-form__performance-container {
  display: flex;
  margin: auto;
  margin-bottom: 30px;
}

.cast-form__performance-graph {
  width: 50%;
  float: left;
}

.cast-form__performance-table table {
  margin-left: 20px;
  margin-top: 8px;
  margin-bottom: 25px;
  width: 20%;
}

.cast-form__performance-table td {
  border: transparent;
}

.cast-form__student-detail-table table {
  width: 500px;
  margin-bottom: 30px;
}

.cast-form__student-detail-table td {
  border-color: transparent;
}

.cast-form__student-detail-table td:not(:nth-of-type(1)) span {
  display: inline-block;
  line-height: 2.125;
  height: 30px;
  width: 30px;
}

.cast-form__student-detail-table td:not(:nth-of-type(1)) {
  padding: 0;
  width: 30px;
}

.cast-form__student-detail-table td:nth-of-type(1) {
  width: 40px;
}

.cast-form__student-detail-table td:nth-of-type(2) {
  border-left: 2px solid #e0e0e0;
}

.cast-form__student-detail-table tr:last-child td:not(:first-child) {
  border-bottom: 2px solid #e0e0e0;
}

.cast-form__rating-scale {
  line-height: 2;
  margin-bottom: 20px;
}

.cast-form-rearrange-box {
  position: relative;
  display: flex;
  padding: 24px 24px 12px !important;
  margin-bottom: 24px;
  min-width: 240px;
  user-select: none;
  background: white;
  transition: opacity 0.4s ease-in-out;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, .2), 0 2px 2px 0 rgba(0, 0, 0, .14), 0 1px 5px 0 rgba(0, 0, 0, .12);

  .cast-form-rearrange {
    background-color: rgba(1, 120, 188, 0.11);
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 10%;
    min-width: 24px;
    cursor: pointer;
    transition: all 0.12s linear;
  }

  .cast-form-rearrange-direction {
    height: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      background-color: rgba(1, 120, 188, 0.055);
    }
  }

  .cast-form-rearrange-direction-single {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      background-color: rgba(1, 120, 188, 0.055);
    }
  }

  .cast-form__arrow {
    font-size: 45px;
    color: #284358;
  }

  .cast-form__checkbox {
    width: 56px;
    margin-bottom: 12px;
  }

  .cast-form__radio-group {
    display: flex;
    flex-direction: row;
    align-items: center;

    > :first-child {
      margin-right: 8px;
      font-weight: 600;
    }
  }

  .cast-form__box {
    display: flex;
    align-items: flex-start;
  }

  .cast-form__box-add-answer {
    display: flex;
    align-items: flex-start;
    margin: 8px 0px 8px 100px;
  }

  .cast-form__field {
    width: 50%;
    min-width: 240px;
    margin-bottom: 12px;

    .MuiTextField-root {
      width: 100%;
    }
  }

  .cast-form__feedback {
    margin-left: 8px;
    cursor: pointer;
    min-width: 180px;

    svg {
      fill: #284358;
    }

    //.MuiSvgIcon-root {
    //  animation: sizeChange 0.24s;
    //}
    //
    //@keyframes sizeChange {
    //  from {
    //    transform: scale(2);
    //  }
    //  to {
    //    transform: scale(1);
    //  }
    //}
  }

  .cast-form__feedback-message {
    margin-bottom: 12px;
  }

  .cast-form__correct-answer-message {
    margin: 25px 0px 0px 50px;
    color: red;
    font-size: 14px;
  }

  .cast-form__feedback-icon {
    display: flex;
    align-items: center;
  }

  .cast-form__add-answer-icon {
    display: flex;
    align-items: center;

    svg {
      fill: #284358;
    }

    span {
      margin-left: 10px;
    }
  }

  .cast-form__feedback-box {
    position: relative;
    display: flex;
    flex-direction: column;

    &:hover {
      .cast-form__icon {
        opacity: 1;
        transform: none;
      }
    }
  }

  .cast-form__icon {
    position: absolute;
    top: -10px;
    right: -10px;
    background: #fafafa;
    fill: red;
    opacity: 0;
    transform: scale(0.8);
    transition: all 0.2s cubic-bezier(0.27, 0.09, 0.83, 1);
    cursor: pointer;
  }

  .cast-form__icon_answer {
    position: absolute;
    top: -10px;
    right: -10px;
    background: #fafafa;
    fill: red;
    opacity: 0;
    transform: scale(0.8);
    transition: all 0.2s cubic-bezier(0.27, 0.09, 0.83, 1);
    cursor: pointer;
  }

  .PrivateNotchedOutline-legendLabelled-117 > span {
    padding-right: 5px;
    padding-left: 0;
    font-size: 11px;
  }
}

