.cast-container {
  height: 100vh;
  overflow: hidden;
}

.cast-header {
  position: fixed;
  z-index: 9;
  height: 64px;
  display: flex;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  padding: 12px 8px;
  background: white;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0px rgba(0, 0, 0, 0.14), 0 1px 5px 0px rgba(0, 0, 0, 0.12);
  border-top: 1px solid rgba(0, 0, 0, 0.16);
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);

  .cast-header__title {
    width: calc(100% - 48px);
    height: 22px;
    line-height: 22px;
    font-size: 18px;
    font-weight: normal;
  }

  .cast-header__section {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    flex-grow: 1;
    justify-content: center;
    height: 40px;

    &:last-child {
      align-items: flex-end;
    }
  }

  .cast-header__icon {
    margin-left: 8px;
    padding: 3px;
    height: 50px;
    border-radius: 4px;
    cursor: pointer;
  }

  .cast-header__link {
    display: flex;
    align-items: flex-end;
    align-self: start;
    text-decoration: none;
    color: #0178bc;
    transition: opacity 0.2s linear;

    &:hover {
      opacity: 0.7;
    }
  }

  .cast-header__link-icon {
    height: 24px;
    width: 24px;
    margin-left: 4px;
  }

  .cast-header__action {
    text-transform: capitalize;
    text-decoration: none;
  }

  .cast-header__nav {
    border: 1px solid #80808038;
  }
}

.cast-ctsr__section {
  margin-top: -10px;
  margin-bottom: 15px;

  .MuiAccordionSummary-root {
    flex-direction: row-reverse;

    .MuiAccordionSummary-content{
      margin-left: 10px;
    }
  }

  iframe {
    margin: 0 auto;
    display: block;
  }
}

.cast-content {
  position: relative;
  top: 64px;
  padding: 24px 12% 8px;
  height: calc(100vh - 64px);
  overflow: auto;
  background: #284358;
}

.cast-table {

  .cast-table__sort-icon {
    position: absolute;
    width: 24px;
    height: 24px;
    opacity: 0;
    vertical-align: inherit;
    transition: all 0.2s linear;

    &.cast-table__sort-icon--desc {
      transform: rotate(180deg);
    }

    &.cast-table__sort-icon--visible {
      opacity: 1 !important;
    }
  }

  &.cast-table--empty {
    &:after {
      content: 'No data';
      margin: 40px 0;
      width: 100%;
      height: 80px;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      overflow: hidden;
      background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'><path d='M20.9,8.3l-7.6-7.8C13,0.2,12.6,0,12.2,0H5.3C4.4,0,3.7,0.7,3.7,1.6v12.6c0,0,0,0,0,0c-1.3,1.2-1.8,2.5-1.9,3.9    c0,0.1,0,0.2,0,0.3c0,3.1,2.5,5.6,5.6,5.6c0.9,0,1.7-0.2,2.5-0.6l0,0c0,0,0.1,0,0.1-0.1c0,0,0,0,0.1,0h9.6c0.9,0,1.6-0.7,1.6-1.6    V9.4C21.3,9,21.2,8.6,20.9,8.3z M13,1.1l7.2,7.5h-6.9c-0.2,0-0.3-0.1-0.3-0.3V1.1z M2.4,18.4c0-0.1,0-0.2,0-0.3    c0-1.1,0.8-2.8,1.8-3.5c0.9-0.7,2-1.2,3.2-1.2c2.7,0,5,2.3,5,5c0,1.5-0.7,2.9-1.8,3.9c-0.2,0.2-0.4,0.3-0.7,0.5    c-0.7,0.4-1.6,0.7-2.5,0.7C4.7,23.4,2.4,21.1,2.4,18.4z M20.7,21.7c0,0.5-0.4,1-1,1h-8.7c1.3-1.1,2-2.6,2-4.3c0-3.1-2.5-5.6-5.6-5.6    c-1.1,0-2.2,0.3-3.1,0.9V1.6c0-0.5,0.4-1,1-1h6.9c0.1,0,0.1,0,0.2,0v7.6c0,0.5,0.4,1,1,1h7.3c0,0.1,0,0.1,0,0.2L20.7,21.7L20.7,21.7    z M9.4,19.9c0.1,0.1,0.1,0.3,0,0.4c-0.1,0.1-0.3,0.1-0.4,0l-1.5-1.5l-1.5,1.5c-0.1,0.1-0.3,0.1-0.4,0c-0.1-0.1-0.1-0.3,0-0.4L7,18.4    l-1.5-1.5c-0.1-0.1-0.1-0.3,0-0.4c0.1-0.1,0.3-0.1,0.4,0L7.4,18l1.5-1.5c0.1-0.1,0.3-0.1,0.4,0c0.1,0.1,0.1,0.3,0,0.4l-1.5,1.5    L9.4,19.9z'/></svg>") no-repeat top center;
      background-size: 56px;
      opacity: 0.56;
    }
  }

  .MuiTableCell-alignRight {
    width: 94px;
  }

  thead {
    th {
      position: relative;
      padding: 8px 16px;
      min-width: 80px;
      font-weight: normal;
      user-select: none;

      &:not(:last-child) {
        cursor: pointer;
      }

      &:hover {
        .cast-table__sort-icon {
          opacity: 0.2;
        }
      }
    }

    tr {
      position: relative;
      z-index: 9;
      height: 48px;
      padding: 0;
      border-top: 1px solid #cecece;
      background: #ebebeb;
    }
  }

  tbody {
    td {
      padding: 0 16px;
      height: 39px;
      line-height: 39px;
      outline: none;
      font-size: 12px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    tr {
      transform: scale(1);
      min-height: 39px;
      padding: 0;
      transition: box-shadow 0.32s cubic-bezier(0.27, 0.09, 0.83, 1);

      &:hover {
        box-shadow: inset 1px 0 0 #dadce0, inset -1px 0 0 #dadce0, 0 1px 2px 0 rgba(60, 64, 67, .3), 0 1px 3px 1px rgba(60, 64, 67, .15);

        .cast-table__action {
          opacity: 0.68;
          transform: none;

          &:hover {
            opacity: 1;
          }
        }

      }
    }

    .cast-table__action-group {
      display: flex;
      justify-content: space-between;
    }

    .cast-table__action {
      vertical-align: middle;
      cursor: pointer;
      opacity: 0;
      background: white;
      transform: scale(0.8);
      border: none;
      transition: all 0.32s cubic-bezier(0.27, 0.09, 0.83, 1);

      .MuiIconButton-root {
        transform: none;
      }
    }

    .cast-table__link {
      color: #0178bc;
      cursor: pointer;
      text-decoration: none;
      transition: all 24s linear;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.cast-card {
  margin-bottom: 8px;
  min-width: 340px;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  background: #fff;
  opacity: 0;
  transition: 0.32s cubic-bezier(1, -0.01, 0.68, 0.63);
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, .2), 0 2px 2px 0 rgba(0, 0, 0, .14), 0 1px 5px 0 rgba(0, 0, 0, .12);

  &.cast-card--init {
    opacity: 1;
  }

  .cast-card__action-group {
    display: flex;
    flex-direction: row-reverse;
    margin-bottom: 20px;
    padding: 0 24px;
  }

  .cast-card__action {
    text-decoration: none;

    &:not(:last-child) {
      margin-left: 24px;
    }
  }

  .cast-card__buttons {
    margin: 30px 0px 10px 30px;

    .cast-card__button {
      text-decoration: none;
      margin-right: 30px;
    }
  }

  .cast-card__grid-container {
    padding-right: 1em;
  }

  .cast-card__bar {
    padding: 70px 70px 40px 70px !important;
  }

  .cast-card__labels  {
    padding-bottom: 10px;
  }

  .cast-card__labels-left {
    padding-left: 4em !important;
  }
}

.cast-toolbar {
  display: flex;
  align-items: center;
  height: 48px;
  padding: 8px 16px;
  transition: padding linear 240ms;
  overflow: hidden;
  justify-content: space-between;

  .cast-toolbar__search {
    position: relative;
    flex-basis: 0;
    opacity: 0;
    overflow: hidden;
    left: -32px;
    height: 46px;
    padding-top: 4px;

    .MuiIconButton-edgeStart {
      position: absolute;
      left: 8px;
      top: -5px;
    }

    .MuiOutlinedInput-inputAdornedEnd {
      padding-left: 32px;
    }

    .MuiInputLabel-outlined.MuiInputLabel-marginDense {
      transform: translate(35px, 16px) scale(1);
    }

    .MuiInputLabel-outlined.MuiInputLabel-shrink {
      transform: translate(14px, 0) scale(0.75);
    }
  }

  &.cast-toolbar--search-show {
    .cast-toolbar__search-icon {
      animation: 0.32s both cast-button-fade-out;
      -webkit-animation: 0.32s both cast-button-fade-out;
      -moz-animation: 0.32s both cast-button-fade-out;
    }

    .cast-toolbar__search {
      max-width: 240px;
      animation: 0.32s 0.16s both cast-search-move-in, 0.32s 0.16s both cast-search-fade-in;
      -webkit-animation: 0.32s 0.16s both cast-search-move-in, 0.32s 0.16s both cast-search-fade-in;
      -moz-animation: 0.32s 0.16s both cast-search-move-in, 0.32s 0.16s both cast-search-fade-in;
      margin-right: -32px;
    }
  }

  &.cast-toolbar--search-hide {
    .cast-toolbar__search-icon {
      animation: 0.32s 0.16s both cast-button-fade-in;
      -webkit-animation: 0.32s 0.16s both cast-button-fade-in;
      -moz-animation: 0.32s 0.16s both cast-button-fade-in;
    }

    .cast-toolbar__search {
      animation: 0.32s 0.16s both cast-search-move-out, 0.32s both cast-search-fade-out;
      -webkit-animation: 0.32s 0.16s both cast-search-move-out, 0.32s both cast-search-fade-out;
      -moz-animation: 0.32s 0.16s both cast-search-move-out, 0.32s both cast-search-fade-out;
      margin-right: -32px;
    }
  }

  .cast-toolbar__section {
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;

    &:first-child {
      flex-grow: 1;
      width: 0;
    }
  }

  .cast-toolbar__title {
    font-size: 16px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    & {
      margin-right: 16px;
    }

    [dir=rtl] & {
      margin-right: 16px;
    }
  }

  .cast-toolbar__action {
    text-decoration: none;
    margin: 5px;
  }

  .cast-toolbar__action-icon {
    margin-right: 8px;

    &.cast-toolbar__action-icon--blue {
      fill: #284358;
    }
  }
}

@media only screen and (max-width: 500px) {
  .cast-content {
    margin: 0 8px;
  }

  .cast-header__link-text {
    display: none;
  }
}

.MuiDialog-paperWidthSm {
  transform: translateY(-60%);
}

@keyframes cast-button-fade-out {
  0% {
    opacity: 1;
    animation-timing-function: cubic-bezier(0.27, 0.09, 0.83, 1);
  }

  100% {
    opacity: 0;
  }
}

@-webkit-keyframes cast-button-fade-out {
  0% {
    opacity: 1;
    -webkit-animation-timing-function: cubic-bezier(0.27, 0.09, 0.83, 1);
  }

  100% {
    opacity: 0;
  }
}

@-moz-keyframes cast-button-fade-out {
  0% {
    opacity: 1;
    -moz-animation-timing-function: cubic-bezier(0.27, 0.09, 0.83, 1);
  }

  100% {
    opacity: 0;
  }
}


@keyframes cast-search-fade-in {
  0% {
    opacity: 0;
    animation-timing-function: cubic-bezier(0.27, 0.09, 0.83, 1);
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes cast-search-fade-in {
  0% {
    opacity: 0;
    -webkit-animation-timing-function: cubic-bezier(0.27, 0.09, 0.83, 1);
  }

  100% {
    opacity: 1;
  }
}

@-moz-keyframes cast-search-fade-in {
  0% {
    opacity: 0;
    -moz-animation-timing-function: cubic-bezier(0.27, 0.09, 0.83, 1);
  }

  100% {
    opacity: 1;
  }
}

@keyframes cast-search-move-in {
  0% {
    margin: 0;
    flex-basis: 0;
    animation-timing-function: cubic-bezier(0.12, 0.87, 0.15, 1);
  }

  100% {
    flex-basis: 240px;
  }
}

@-webkit-keyframes cast-search-move-in {
  0% {
    margin: 0;
    flex-basis: 0;
    -webkit-animation-timing-function: cubic-bezier(0.12, 0.87, 0.15, 1);
  }

  100% {
    flex-basis: 240px;
  }
}

@-moz-keyframes cast-search-move-in {
  0% {
    margin: 0;
    flex-basis: 0;
    -moz-animation-timing-function: cubic-bezier(0.12, 0.87, 0.15, 1);
  }

  100% {
    flex-basis: 240px;
  }
}

@keyframes cast-button-fade-in {
  0% {
    opacity: 0;
    animation-timing-function: cubic-bezier(0.27, 0.09, 0.83, 1);
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes cast-button-fade-in {
  0% {
    opacity: 0;
    -webkit-animation-timing-function: cubic-bezier(0.27, 0.09, 0.83, 1);
  }

  100% {
    opacity: 1;
  }
}

@-moz-keyframes cast-button-fade-in {
  0% {
    opacity: 0;
    -moz-animation-timing-function: cubic-bezier(0.27, 0.09, 0.83, 1);
  }

  100% {
    opacity: 1;
  }
}

@keyframes cast-search-fade-out {
  0% {
    opacity: 1;
    animation-timing-function: cubic-bezier(0.27, 0.09, 0.83, 1);
  }

  100% {
    opacity: 0;
  }
}

@-webkit-keyframes cast-search-fade-out {
  0% {
    opacity: 1;
    -webkit-animation-timing-function: cubic-bezier(0.27, 0.09, 0.83, 1);
  }

  100% {
    opacity: 0;
  }
}

@-moz-keyframes cast-search-fade-out {
  0% {
    opacity: 1;
    -moz-animation-timing-function: cubic-bezier(0.27, 0.09, 0.83, 1);
  }

  100% {
    opacity: 0;
  }
}

@keyframes cast-search-move-out {
  0% {
    flex-basis: 240px;
    animation-timing-function: cubic-bezier(0.12, 0.87, 0.15, 1);
  }

  100% {
    flex-basis: 0;
    margin: 0;
  }
}

@-webkit-keyframes cast-search-move-out {
  0% {
    flex-basis: 240px;
    -webkit-animation-timing-function: cubic-bezier(0.12, 0.87, 0.15, 1);
  }

  100% {
    flex-basis: 0;
    margin: 0;
  }
}

@-moz-keyframes cast-search-move-out {
  0% {
    flex-basis: 240px;
    -moz-animation-timing-function: cubic-bezier(0.12, 0.87, 0.15, 1);
  }

  100% {
    flex-basis: 0;
    margin: 0;
  }
}