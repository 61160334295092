.cast-popup {
  .cast-popup__btn {
    border-color: #d8d8d8 #d1d1d1 #bababa;
    border-style: solid;
    border-width: 1px;
    padding: 4px 24px;
    font-size: 18px;
    border-radius: 4px;
  }
}

.input-file-container {
  position: relative;
  width: 225px;
}

.input-file {
  position: absolute;
  top: 0; left: 0;
  width: 225px;
  opacity: 0;
  padding: 14px 0;
  cursor: pointer;

  &:hover + .input-file-trigger,
  &:focus + .input-file-trigger,
  .input-file-trigger:hover,
  .input-file-trigger:focus {
    background: #34495E;
    color: #39D2B4;
  }

  .input-file__trigger {
    display: block;
    padding: 14px 45px;
    background: #39D2B4;
    color: #fff;
    font-size: 1em;
    transition: all .4s;
    cursor: pointer;
  }
}


.file-return {
  margin: 0;
  .file-return {
    font-style: italic;
    font-size: .9em;
    font-weight: bold;
  }

  &:not(:empty) {
    margin: 1em 0;
  }

  &:not(:empty):before {
    content: "Selected file: ";
    font-style: normal;
    font-weight: normal;
  }
}

